<template>
  <main class="main">
    <div class="container">
      <div class="block-tokens">
        <div class="wrapper"> 
          <h1 class="color_green title">Tokens</h1>
          <router-link to="/create-token" class="btn">
            <div class="btn__title">Create token</div>
          </router-link>
        </div>
        <div class="table-wrapper">
          <table class="table">
            <thead>
              <tr> 
                <th class="td-sticky">Token</th>
                <th>Creation date</th>
                <th>Total supply</th>
                <th>Contract address</th>
                <th>Ownership</th>
                <th>Mintable</th>
                <th>Management</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="td-sticky">
                  <div class="td-wrapper">
                    <div class="flex"><img class="image" :src="require('@/assets/img/icons/bitcoin.webp')">
                      <div class="text">
                        <p>Cosmos</p>
                        <p class="text-gradient">$ATOM</p>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="td-wrapper">2024-01-28</div>
                </td>
                <td>
                  <div class="td-wrapper">1,000,000,000.00</div>
                </td>
                <td>
                  <div class="td-wrapper">0xer...2331df</div>
                </td>
                <td>
                  <div class="td-wrapper">Owner</div>
                </td>
                <td> 
                  <div class="td-wrapper">
                    <button class="icon info"></button>
                    <button class="btn btn_outline">
                      <div class="btn__text">Mint</div>
                    </button>
                  </div>
                </td>
                <td> 
                  <div class="td-wrapper">
                    <button class="icon setting"></button>
                    <button class="icon trash"></button>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="td-sticky">
                  <div class="td-wrapper">
                    <div class="flex"><img class="image" :src="require('@/assets/img/icons/bitcoin.webp')">
                      <div class="text">
                        <p>Cosmos</p>
                        <p class="text-gradient">$ATOM</p>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="td-wrapper">2024-01-28</div>
                </td>
                <td>
                  <div class="td-wrapper">1,000,000,000.00</div>
                </td>
                <td>
                  <div class="td-wrapper">0xer...2331df</div>
                </td>
                <td>
                  <div class="td-wrapper">Owner</div>
                </td>
                <td> 
                  <div class="td-wrapper">
                    <button class="icon info"></button>
                    <button class="btn btn_outline">
                      <div class="btn__text">Mint</div>
                    </button>
                  </div>
                </td>
                <td> 
                  <div class="td-wrapper">
                    <button class="icon setting"></button>
                    <button class="icon trash"></button>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="td-sticky">
                  <div class="td-wrapper">
                    <div class="flex"><img class="image" :src="require('@/assets/img/icons/bitcoin.webp')">
                      <div class="text">
                        <p>Cosmos</p>
                        <p class="text-gradient">$ATOM</p>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="td-wrapper">2024-01-28</div>
                </td>
                <td>
                  <div class="td-wrapper">1,000,000,000.00</div>
                </td>
                <td>
                  <div class="td-wrapper">0xer...2331df</div>
                </td>
                <td>
                  <div class="td-wrapper">Owner</div>
                </td>
                <td> 
                  <div class="td-wrapper">
                    <button class="icon info"></button>
                    <button class="btn btn_outline">
                      <div class="btn__text">Mint</div>
                    </button>
                  </div>
                </td>
                <td> 
                  <div class="td-wrapper">
                    <button class="icon setting"></button>
                    <button class="icon trash"></button>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="td-sticky">
                  <div class="td-wrapper">
                    <div class="flex"><img class="image" :src="require('@/assets/img/icons/bitcoin.webp')">
                      <div class="text">
                        <p>Cosmos</p>
                        <p class="text-gradient">$ATOM</p>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="td-wrapper">2024-01-28</div>
                </td>
                <td>
                  <div class="td-wrapper">1,000,000,000.00</div>
                </td>
                <td>
                  <div class="td-wrapper">0xer...2331df</div>
                </td>
                <td>
                  <div class="td-wrapper">Owner</div>
                </td>
                <td> 
                  <div class="td-wrapper">
                    <button class="icon info"></button>
                    <button class="btn btn_outline">
                      <div class="btn__text">Mint</div>
                    </button>
                  </div>
                </td>
                <td> 
                  <div class="td-wrapper">
                    <button class="icon setting"></button>
                    <button class="icon trash"></button>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="td-sticky">
                  <div class="td-wrapper">
                    <div class="flex"><img class="image" :src="require('@/assets/img/icons/bitcoin.webp')">
                      <div class="text">
                        <p>Cosmos</p>
                        <p class="text-gradient">$ATOM</p>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="td-wrapper">2024-01-28</div>
                </td>
                <td>
                  <div class="td-wrapper">1,000,000,000.00</div>
                </td>
                <td>
                  <div class="td-wrapper">0xer...2331df</div>
                </td>
                <td>
                  <div class="td-wrapper">Owner</div>
                </td>
                <td> 
                  <div class="td-wrapper">
                    <button class="icon info"></button>
                    <button class="btn btn_outline">
                      <div class="btn__text">Mint</div>
                    </button>
                  </div>
                </td>
                <td> 
                  <div class="td-wrapper">
                    <button class="icon setting"></button>
                    <button class="icon trash"></button>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="td-sticky">
                  <div class="td-wrapper">
                    <div class="flex"><img class="image" :src="require('@/assets/img/icons/bitcoin.webp')">
                      <div class="text">
                        <p>Cosmos</p>
                        <p class="text-gradient">$ATOM</p>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="td-wrapper">2024-01-28</div>
                </td>
                <td>
                  <div class="td-wrapper">1,000,000,000.00</div>
                </td>
                <td>
                  <div class="td-wrapper">0xer...2331df</div>
                </td>
                <td>
                  <div class="td-wrapper">Owner</div>
                </td>
                <td> 
                  <div class="td-wrapper">
                    <button class="icon info"></button>
                    <button class="btn btn_outline">
                      <div class="btn__text">Mint</div>
                    </button>
                  </div>
                </td>
                <td> 
                  <div class="td-wrapper">
                    <button class="icon setting"></button>
                    <button class="icon trash"></button>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="td-sticky">
                  <div class="td-wrapper">
                    <div class="flex"><img class="image" :src="require('@/assets/img/icons/bitcoin.webp')">
                      <div class="text">
                        <p>Cosmos</p>
                        <p class="text-gradient">$ATOM</p>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="td-wrapper">2024-01-28</div>
                </td>
                <td>
                  <div class="td-wrapper">1,000,000,000.00</div>
                </td>
                <td>
                  <div class="td-wrapper">0xer...2331df</div>
                </td>
                <td>
                  <div class="td-wrapper">Owner</div>
                </td>
                <td> 
                  <div class="td-wrapper">
                    <button class="icon info"></button>
                    <button class="btn btn_outline">
                      <div class="btn__text">Mint</div>
                    </button>
                  </div>
                </td>
                <td> 
                  <div class="td-wrapper">
                    <button class="icon setting"></button>
                    <button class="icon trash"></button>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="td-sticky">
                  <div class="td-wrapper">
                    <div class="flex"><img class="image" :src="require('@/assets/img/icons/bitcoin.webp')">
                      <div class="text">
                        <p>Cosmos</p>
                        <p class="text-gradient">$ATOM</p>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="td-wrapper">2024-01-28</div>
                </td>
                <td>
                  <div class="td-wrapper">1,000,000,000.00</div>
                </td>
                <td>
                  <div class="td-wrapper">0xer...2331df</div>
                </td>
                <td>
                  <div class="td-wrapper">Owner</div>
                </td>
                <td> 
                  <div class="td-wrapper">
                    <button class="icon info"></button>
                    <button class="btn btn_outline">
                      <div class="btn__text">Mint</div>
                    </button>
                  </div>
                </td>
                <td> 
                  <div class="td-wrapper">
                    <button class="icon setting"></button>
                    <button class="icon trash"></button>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="td-sticky">
                  <div class="td-wrapper">
                    <div class="flex"><img class="image" :src="require('@/assets/img/icons/bitcoin.webp')">
                      <div class="text">
                        <p>Cosmos</p>
                        <p class="text-gradient">$ATOM</p>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="td-wrapper">2024-01-28</div>
                </td>
                <td>
                  <div class="td-wrapper">1,000,000,000.00</div>
                </td>
                <td>
                  <div class="td-wrapper">0xer...2331df</div>
                </td>
                <td>
                  <div class="td-wrapper">Owner</div>
                </td>
                <td> 
                  <div class="td-wrapper">
                    <button class="icon info"></button>
                    <button class="btn btn_outline">
                      <div class="btn__text">Mint</div>
                    </button>
                  </div>
                </td>
                <td> 
                  <div class="td-wrapper">
                    <button class="icon setting"></button>
                    <button class="icon trash"></button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="modal">
      <div class="modal__wrapper">
        <div class="modal__shadow"></div>
        <div class="delete-modal modal__content">
          <button class="modal__close"><i class="icon modal-close"></i></button>
          <div class="text">Delete <span class="color_red">$ATOM</span> from token list?</div>
          <button class="btn btn_small btn_red">
            <div class="btn__text">Delete</div>
          </button>
        </div>
      </div>
    </div>

    <div class="modal">
      <div class="modal__wrapper">
        <div class="modal__shadow"></div>
        <div class="form-modal mint-modal modal__content">
          <button class="modal__close"><i class="icon modal-close"></i></button>
          <div class="title title-second">Mint</div>
          <div class="form">
            <div class="input-wrapper"> 
              <div class="input-base">
                <input class="input" type="text" placeholder="Enter wallet address">
              </div>
            </div>
            <div class="input-wrapper"> 
              <div class="input-base">
                <input class="input" type="text" placeholder="Enter amount of tokens">
              </div>
            </div>
          </div>
          <button class="add-wallet">
            <div class="icon add"></div><span>Add another wallet</span>
          </button>
          <button class="btn btn_outline-1">
            <div class="btn__text">Mint</div>
          </button>
        </div>
      </div>
    </div>

    <div class="modal">
      <div class="modal__wrapper">
        <div class="modal__shadow"></div>
        <div class="form-modal vesting-modal modal__content">
          <button class="modal__close"><i class="icon modal-close"></i></button>
          <div class="title title-second">Vesting</div>
          <div class="form">
            <div class="input-wrapper"> 
              <div class="input-base">
                <input class="input" type="text" placeholder="Receiver’s wallet address">
              </div>
            </div>
            <div class="input-wrapper"> 
              <div class="input-base">
                <input class="input" type="text" placeholder="Amount of tokens">
              </div>
            </div>
            <div class="input-wrapper"> 
              <div class="input-base">
                <input class="input" type="text" placeholder="Cleef date">
              </div>
            </div>
            <div class="flex">
              <div class="input-wrapper"> 
                <div class="input-base">
                  <input class="input" type="text" placeholder="Time gap">
                </div>
              </div>
              <div class="input-wrapper"> 
                <div class="input-base">
                  <input class="input" type="text"><i class="icon arrow-circle"></i>
                  <div class="options">
                    <div class="options__body">
                      <button class="options__item" data-option="1">Months</button>
                      <button class="options__item" data-option="1">Months</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-wrapper input-wrapper_big">
              <div class="input-base">
                <input class="input" type="text" placeholder="Interest">
              </div>
            </div>
          </div>
          <button class="btn btn_outline-1">
            <div class="btn__text">Lock</div>
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'Tokens',
}
</script>
